export const namespaced = true;

export const getters = {
  current: (s, g, rootState) => rootState.directories
    .find((dir) => dir.slug === rootState.route.params.directory),
  count: (s, g, rootState) => rootState.directories.length,
  index: (s, getter, rootState) => {
    const directory = getter.current ?? rootState.directories[0];
    const insightsName = rootState.name.toLowerCase().replace(/ /g, '-');
    return `${insightsName}_${directory.slug}`;
  },
  primaryFilter: (s, g) => g?.current.fields.find((f) => f.key === g.current.primary_filter.field),
};
