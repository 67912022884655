const state = require('../store/state.json');

export default [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/Home.vue'),
    beforeEnter: (to, from, next) => {
      if (state.directories.length < 2) {
        next({
          name: 'directory-home',
          params: { directory: state.directories[0].slug },
        });
      } else {
        next();
      }
    },
  },
  {
    path: '/404',
    name: '404',
    component: () => import('../views/404.vue'),
  },
  {
    path: '/expert',
    name: 'expert',
    component: () => import('../views/Expert.vue'),
  },
  {
    path: '/:directory',
    component: () => import('../views/Directory/Home.vue'),
    meta: {
      sitemap: {
        slugs: state.directories.map((directory) => directory.slug),
      },
    },
    children: [
      { // TODO: Refactor this and grid to use a setting not a path
        path: '',
        name: 'directory',
        component: () => import('../views/Directory/Search.vue'),
      },
      {
        path: 'grid',
        name: 'directory-grid',
        component: () => import('../views/Directory/Grid.vue'),
      },
      {
        path: 'home',
        name: 'directory-home',
        component: () => import('../views/Directory/Landing.vue'),
        meta: {
          sitemap: {
            slugs: state.directories.map((directory) => directory.slug),
          },
        },
      },
      {
        path: ':record',
        name: 'record',
        component: () => import('../views/Record.vue'),
        meta: {
          sitemap: {
            ignoreRoute: true,
          },
        },
      },
    ],
  },
];
