export const namespaced = true;

function filterStaticAds(ads) {
  // shuffle
  // eslint-disable-next-line no-plusplus
  for (let i = ads.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    // eslint-disable-next-line no-param-reassign
    [ads[i], ads[j]] = [ads[j], ads[i]];
  }

  // validate
  return ads.filter((ad) => !!(ad.src && ad.link));
}

export const state = {
  provider: '',
  results_ads: [],
  leaderboard_ads: [],
  record_ads: [],
};

export const mutations = {
  SET_PROVIDER(state, provider) {
    state.provider = provider;
  },
  SET_RESULTS_ADS(state, ads) {
    state.results_ads = ads;
  },
  SET_LEADERBOARD_ADS(state, ads) {
    state.leaderboard_ads = ads;
  },
  SET_RECORD_ADS(state, ads) {
    state.record_ads = ads;
  },
};

export const actions = {
  get({ commit, rootState }) {
    commit('SET_PROVIDER', rootState.ads.provider);

    // ADVERTSERVE PROVIDER
    if (rootState.ads.provider === 'advertserve') {
      if (rootState.ads.leaderboard_ads) {
        // TODO: migrate to Axios
        fetch(`${rootState.ads.leaderboard_ads}&referrer=${window.location.href}`)
          .then((response) => response.json())
          .then((data) => commit('SET_LEADERBOARD_ADS', data.tags))
          .catch((err) => console.debug(err));
      }

      if (rootState.ads.record_ads) {
        // TODO: migrate to Axios
        fetch(`${rootState.ads.record_ads}&referrer=${window.location.href}`)
          .then((response) => response.json())
          .then((data) => commit('SET_RECORD_ADS', data.tags))
          .catch((err) => console.debug(err));
      }

      if (rootState.ads.results_ads) {
        // TODO: migrate to Axios
        fetch(`${rootState.ads.results_ads}&referrer=${window.location.href}`)
          .then((response) => response.json())
          .then((data) => commit('SET_RESULTS_ADS', data.tags))
          .catch((err) => console.debug(err));
      }
    }

    // STATIC PROVIDER
    if (rootState.ads.provider === 'static') {
      const leaderboard_ads = filterStaticAds(rootState.ads.leaderboard_ads);
      commit('SET_LEADERBOARD_ADS', leaderboard_ads);

      const record_ads = filterStaticAds(rootState.ads.record_ads);
      commit('SET_RECORD_ADS', record_ads);

      const results_ads = filterStaticAds(rootState.ads.results_ads);
      commit('SET_RESULTS_ADS', results_ads);
    }
  },
};
