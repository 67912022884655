<template>
  <div class="footer has-background-dark has-text-white columns is-vcentered is-mobile">
    <div class="column is-3">
      <img
        src="https://s3-us-west-2.amazonaws.com/directory.covamd.com/poweredby.png"
        class="is-powered-by"
        alt="Powered by DataJoe"
      >
    </div>
    <div class="column" />
    <div class="column is-5 is-right buttons">
      <Sharing />
    </div>
  </div>
</template>

<script>
import Sharing from '@/components/Global/Sharing.vue';

export default {
  components: {
    Sharing,
  },
};
</script>
